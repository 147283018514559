import { Typography } from '@mui/material';
import React from 'react'

const UnderConstruction = () => {
	return (
		<>
		<Typography variant="h4">
			I really have to finish this...
		</Typography>
		</>
	)
}

export default UnderConstruction;